<template>
  <div class="information">
    <div class="customer">
      <el-card class="box-card">
        <div class="title" style="margin-bottom: 20px">客户概况</div>
        <div class="customer_card">
          <el-card class="box-card">
            <div>
              <span>今日新增</span>
              <span class="title">{{todayADD}}</span>
            </div>
            <div style="margin-top:40px">
              <span>对比昨日</span>
              <span class="title">{{differ}}<i v-show="differ!==0" :class="differ>0?'el-icon-top':'el-icon-bottom'" style="color: teal; font-size: 20px"></i></span>
            </div>
          </el-card>
          <el-card class="box-card">
            <div>
              <span>客户总数</span>
              <span class="title">{{customersCount}}</span>
            </div>
            <div style="margin-top:40px">
              <span>累计客户数</span>
              <span class="title">{{customersAdd}}</span>
            </div>
            <!-- <div>
              <span>跟进中</span>
              <span style="color: #156ED0; font-weight: bold">{{follow}}</span>
            </div> -->
          </el-card>
        </div>
      </el-card>
    </div>
    <div class="add_customer">
      <el-card class="box-card">
        <div class="title" style="margin-bottom: 20px">新增客户统计</div>
        <el-select v-model="date" size="mini" style="width: 125px" @change="handleSelect">
          <el-option
            v-for="item in options"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
        <div id="myChart" style="width: 90%; height: 400px"></div>
      </el-card>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { request } from '../../../../../network';
export default {
  data () {
    return {
      date: '2022',
      options: [],
      todayADD: '',
      differ: '',
      customersCount: '',
      customersAdd: '',
      follow: ''
    };
  },
  mounted () {
    // this.drawLine();
    this.getQryAllTagGrp(JSON.parse(localStorage.getItem('userinfo')).orgId, this.$year);
  },
  methods: {
    // echarts柱状图
    drawLine (xAxisData, seriesData) {
      var chartDom = document.getElementById('myChart');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        color: '#156ED0',
        tooltip: {},
        xAxis: {
          type: 'category',
          data: xAxisData,
          axisLabel: { formatter: '{value} 月' }
        },
        yAxis: {
          type: 'value',
          axisLabel: { formatter: '{value} 人' },
          splitNumber: 2
        },
        legend: {
          data: [ '新增客户数' ],
          x: 'right'
        },
        series: [
          {
            name: '新增客户数',
            data: seriesData,
            type: 'bar',
            barWidth: 40
          }
        ]
      };
      option && myChart.setOption(option);
    },
    getQryAllTagGrp (orgId, year) {
      const that = this;
      request({
        method: 'POST',
        url: '/pcp/org/orgStatistics',
        data: {
          orgId: orgId,
          year: year
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            that.todayADD = res.data.data.todayADD;
            that.differ = res.data.data.differ;
            that.customersCount = res.data.data.customersCount;
            that.follow = res.data.data.follow;
            const xAxisData = Object.keys(res.data.data.statistics);
            const seriesData = Object.values(res.data.data.statistics);
            that.drawLine(xAxisData, seriesData);
            that.options = res.data.data.years;
            that.customersAdd = res.data.data.customersAdd;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSelect (value) {
      this.getQryAllTagGrp(JSON.parse(localStorage.getItem('userinfo')).orgId, value);
    }
  }
};
</script>

<style lang="less" scoped>
.information {
  margin-top: 40px;
  .customer {
    width: 100%;
    > .el-card {
      padding-bottom: 40px;
      .customer_card {
        display: flex;
        .el-card {
          width: 300px;
          height: 150px;
          margin-left: 40px;
          div {
            // margin-top: 20px;
            margin-left: 20px;
            display: flex;
            span {
              margin-left: 20px;
              width: 100px;
            }
          }
        }
      }
    }
  }
  .add_customer {
    margin-top: 20px;
  }
}
.title {
  font-size: 16px;
  font-weight: bold;
}
</style>
